<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MICS</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content">
<div class="container-fluid">
<div class="card card-primary card-outline">
<div class="card-header">
<div class="col-6">
<h5>Sosmed Baru</h5>
</div>
<div class="text-right">
<router-link to="/TampilanSosmed" class="btn btn-primary" style="color:#fff">
<i class="fas fa-angle-left" style="color:#fff"> </i> Kembali
</router-link>
</div>
</div>
<form @submit.prevent="store" class="card-body">
<div class="form-group">
<label for="postTitle">Title ID:</label>
<input type="text" id="judul_id" v-model="post.judul_id" class="form-control" placeholder="Enter Post Title" required>
</div>
<div class="form-group">
<label for="postTitle">Title EN:</label>
<input type="text" id="judul_en" v-model="post.judul_en" class="form-control" placeholder="Enter Post Title" required>
</div>
<div class="form-group">
<label for="author">URL ID:</label>
<input type="text" id="url" v-model="post.url" class="form-control" required>
</div>
<div class="form-group">
<label for="imageUpload">Image:</label>
<input type="file" id="gambar" class="form-control-file" @change="handleImageUpload">
<img v-if="imagePreview" :src="imagePreview" alt="Preview" style="max-width:200px;max-height:200px;margin-top:10px">
</div>
<div class="form-group">
<button type="submit" class="btn btn-primary">Submit</button>
</div>
</form>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import{defineAsyncComponent as t}from"vue";import e from"axios";let NavbarDs=t(()=>import("@/components/NavbarDs.vue")),FooterDs=t(()=>import("@/components/FooterDs.vue")),SidebarDs=t(()=>import("@/components/SidebarDs.vue"));export default{name:"CombinedComponent",components:{FooterDs,NavbarDs,SidebarDs},data:()=>({post:{judul_en:"",judul_id:"",url:"",gambar:null},validationErrors:[],imagePreview:"",editorData:"",isSubmitting:!1}),methods:{async store(){if(!this.validateForm()){alert("Please fill in all required fields.");return}this.isSubmitting=!0;let t=new FormData;t.append("judul_id",this.post.judul_id),t.append("judul_en",this.post.judul_en),t.append("url",this.post.url),t.append("gambar",this.post.gambar);try{let r=await e.post("https://puracigarettesolutions.com:3000/api/sosmed/CreateSosmed",t);console.log("Submitted:",r.data),this.$router.push({name:"TampilanSosmed"})}catch(s){this.handleErrors(s)}finally{this.isSubmitting=!1}},handleErrors(t){t.response&&t.response.data?this.validationErrors=t.response.data:(console.error("Unexpected error structure:",t),this.validationErrors=[{param:"error",msg:"Unexpected error occurred"}])},validateForm(){return this.post.judul_id&&this.post.judul_en&&this.post.url&&this.post.gambar},handleImageUpload(t){this.post.gambar=t.target.files[0],this.imagePreview=URL.createObjectURL(t.target.files[0])}}};
</script>
