<template>
<div v-cloak>
<HeaderCom />
<section class="saasio-breadcurmb-section-detailblog">
<div class="container">
<div class="breadcurmb-title-detailblog"></div>
</div>
</section>
<main v-if="!loading && article">
<div class="filter-blog-section">
<div class="filter-blog-label">
<article class="center-text" v-html="currentLanguage === 'en' ? article.judul_en : article.judul_id"></article>
</div>
<div class="event-links" v-if="article.keyword">
<span v-for="(keyword, i) in article.keyword.split(',')" :key="i">
<a href="#" class="event-link">{{ keyword.trim() }}</a>
</span>
</div>
</div>
<section id="news-feed" class="news-feed-section">
<div class="container">
<div class="blog-feed-content">
<div class="row">
<div class="col-md-12">
<div class="saasio-blog-details-content">
<div class="blog-details-text dia-headline" v-if="article.deskripsi_en || article.deskripsi_id">
<article class="justify-text" v-html="currentLanguage === 'en' ? article.deskripsi_en : article.deskripsi_id"></article>
</div>
<div class="gambarnews" v-if="article.gambar3">
<img :src="getFileUrl(article.gambar3)" :alt="currentLanguage === 'en' ? article.judul_en : article.judul_id" loading="eager" decoding="async" fetchpriority="high">
</div>
<div class="blog-details-text dia-headline" v-if="article.keterangan_en || article.keterangan_id">
<article class="justify-text" v-html="currentLanguage === 'en' ? article.keterangan_en : article.keterangan_id"></article>
</div>
<div class="blog-details-text dia-headline" v-if="article.keterangan_en2 || article.keterangan_id2">
<div class="faq-content">
<article class="justify-text" v-html="processContent(currentLanguage === 'en' ? article.keterangan_en2 : article.keterangan_id2)"></article>
</div>
</div>
<div class="ref-details-text" v-if="hasReferences">
<h5>References</h5>
<div v-for="ref in references" :key="ref.id">
<a :href="ref.link" target="_blank" rel="noopener noreferrer">{{ ref.link }}</a>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</main>
<div v-else-if="loading" class="loading-container">
<div class="loading-spinner">Loading...</div>
</div>
<div v-else-if="error" class="error-container">
{{ error }}
</div>
<section class="blog-tambahan" v-if="relatedArticles.length">
<div class="interesting">
<h2>{{ $t('detailblog.title') }}</h2>
</div>
<div class="container-blog">
<div class="card-blog" v-for="post in relatedArticles" :key="post.id_artikel">
<div class="blog-img">
<img :src="getFileUrl(post.gambar1)" :alt="currentLanguage === 'en' ? post.judul_en : post.judul_id" loading="lazy" decoding="async" class="card-image-blog">
<div class="card-blog-content">
<article class="center-text" v-html="currentLanguage === 'en' ? post.judul_en : post.judul_id"></article>
<router-link :to="generateArticleUrl(post)" @click="scrollToTop">
<button class="read-more-button-blog">{{ $t('detailblog.readMore') }}</button>
</router-link>
</div>
</div>
</div>
</div>
</section>
<FooterCom />
</div>
</template>
<script>
import{ref as e,computed as a,onMounted as t,watch as l}from"vue";import{useRoute as r,useRouter as i}from"vue-router";import{useI18n as o}from"vue-i18n";import u from"axios";import n from"@/components/HeaderCom.vue";import s from"@/components/FooterCom.vue";import{metaMixin as d}from"@/mixins/metaMixin";let BASE_URL="https://puracigarettesolutions.com:3000";export default{name:"DetailBlog",components:{HeaderCom:n,FooterCom:s},mixins:[d],setup(n,{emit:s}){let d=r(),c=i(),{locale:m,t:p}=o(),v=e(null),f=e([]),g=e(!0),h=e(null),k=a(()=>m.value),$=a(()=>v.value?[1,2,3,4].map(e=>({id:e,link:v.value[`link_ref${e}`]})).filter(e=>e.link):[]),B=a(()=>$.value.length>0),A=e=>{if(!e)return"";let a=e.replace(/<[^>]*>/g,"").replace(/&nbsp;/g," ").trim();return(a=a.split(/[:\n]/)[0].trim()).toLowerCase().replace(/[^a-z0-9\s-]/g,"").replace(/\s+/g,"-").replace(/-+/g,"-").replace(/^-+|-+$/g,"")},D=e=>e?`${BASE_URL}/api/artikel/file/${encodeURIComponent(e)}`:"",F=e=>e?e.includes("<blockquote>")?`<div class="faq-container">
                  <h5 class="faq-title">${p("detailblog.faqTitle")}</h5>
                  ${e.replace(/<blockquote>/g,"<blockquote>")}
                </div>`:e:"",b=e=>{let a="en"===k.value?e.judul_en:e.judul_id;return`/DetailBlog/${A(a)}`},j=()=>{window.scrollTo({top:0,behavior:"smooth"})},q=async e=>{try{let a=await u.get(`${BASE_URL}/api/artikel/TampilanArtikel`);a.data?.success&&a.data?.data&&(f.value=a.data.data.filter(a=>a.id_artikel!==e).slice(0,3))}catch(t){console.error("Error fetching related articles:",t),f.value=[]}},x=(e,a)=>e.find(e=>{let t=A(e.judul_en||""),l=A(e.judul_id||"");return t===a||l===a}),E=async()=>{let e=d.params.slug;if(!e){h.value=p("errors.invalidUrl"),g.value=!1;return}g.value=!0,h.value=null;try{let a=await u.get(`${BASE_URL}/api/artikel/TampilanArtikel`);if(a.data?.success&&a.data?.data){let t=x(a.data.data,e);if(t){v.value=t,await q(t.id_artikel),s("article-loaded",t);let l="en"===k.value?t.judul_en:t.judul_id,r=A(l);e!==r&&c.replace(`/DetailBlog/${r}`)}else h.value=p("errors.articleNotFound")}}catch(i){console.error("Error fetching article:",i),h.value=p("errors.fetchFailed")}finally{g.value=!1}};return l([()=>d.params.slug,()=>m.value],()=>{E()},{immediate:!0}),t(()=>{E()}),{article:v,relatedArticles:f,loading:g,error:h,currentLanguage:k,references:$,hasReferences:B,createSlug:A,getFileUrl:D,processContent:F,generateArticleUrl:b,scrollToTop:j}},methods:{handleArticleLoaded(e){this.$nextTick(()=>{this.updateMetaForDetailBlog&&(this.article=e,this.updateMetaForDetailBlog())})}},watch:{article:{immediate:!0,handler(e){e&&this.updateMetaForDetailBlog&&this.updateMetaForDetailBlog()}}}};
</script>
<style src="@/assets/css/view/DetailBlog.css"></style>