<template>
  <div class=sidebar>
<div class=form-inline>
<div class=input-group data-widget=sidebar-search>
<input class="form-control form-control-sidebar" type=search placeholder=Search aria-label=Search>
<div class=input-group-append>
<button class="btn btn-sidebar">
<i class="fas fa-search fa-fw"></i>
</button>
</div>
</div>
</div>
<nav class=mt-2>
<ul class="nav nav-pills nav-sidebar flex-column" data-widget=treeview role=menu data-accordion=false>
<li v-for="item in accessibleMenuItems" :key=item.id>
<router-link :to=item.link class=nav-link :class="{ active: item.link === $route.path }">
<i :class=item.icon></i>
{{ item.title }}
</router-link>
</li>
</ul>
</nav>
</div>
</template>
<script>
export default{computed:{loggedInUserRole(){return this.$store.getters.loggedInUserRole},accessibleMenuItems(){return this.menuItems.filter(i=>i.allowedRoles.includes(this.loggedInUserRole))}},data:()=>({menuItems:[{id:1,title:"Dashboard",link:"/DashboardDs",icon:"nav-icon fas fa-tachometer-alt",allowedRoles:[1,2]},{id:2,title:"Artikel",link:"/TampilanArtikel",icon:"nav-icon fas fa-book",allowedRoles:[1,2]},{id:3,title:"Produk",link:"/TampilanProduk",icon:"nav-icon far fa-image",allowedRoles:[1,2]},{id:4,title:"Detail Produk",link:"/TampilanDetailProduk",icon:"nav-icon far fa-image",allowedRoles:[1,2]},{id:5,title:"Ketegori",link:"/Tampilankategori",icon:"nav-icon fas fa-book",allowedRoles:[1]},{id:6,title:"Ulang Tahun",link:"/UlangTahun",icon:"nav-icon far fa-calendar-alt",allowedRoles:[1,2]},{id:7,title:"Etiket",link:"/TampilanEtiket",icon:"nav-icon far fa-envelope",allowedRoles:[1,2]},{id:8,title:"CTP",link:"/TampilanCtp",icon:"nav-icon fas fa-edit",allowedRoles:[1,2]},{id:9,title:"Cigarette Paper",link:"/TampilanCp",icon:"nav-icon fas fa-edit",allowedRoles:[1,2]},{id:10,title:"Filter Tips",link:"/TampilanFilter",icon:"nav-icon far fa-envelope",allowedRoles:[1,2]},{id:11,title:"PRE-ROLLED CONES",link:"/TampilanCones",icon:"nav-icon far fa-envelope",allowedRoles:[1,2]},{id:12,title:"Sosial Media",link:"/TampilanSosmed",icon:"nav-icon fas fa-edit",allowedRoles:[1,2]},{id:13,title:"Question",link:"/TampilanQuestion",icon:"nav-icon fas fa-edit",allowedRoles:[1,2]},{id:14,title:"Customer",link:"/TampilanCustomer",icon:"nav-icon fas fa-edit",allowedRoles:[1,2]},{id:15,title:"Promo",link:"/TampilanPromo",icon:"nav-icon fas fa-edit",allowedRoles:[1,2]},{id:16,title:"Users",link:"/TampilanUsers",icon:"nav-icon far fa-plus-square",allowedRoles:[1]},{id:17,title:"Logout",link:"/LoginDs",icon:"nav-icon far fa-plus-square",allowedRoles:[1,2]}]})};
</script>
