<template>
<HeaderCom />
<section class="saasio-breadcurmb-section-contact">
<div class="container">
<div class="breadcurmb-title-contact">
<h1>{{ $t('ContactUs.title') }}</h1>
<p>{{ $t('ContactUs.subtitle') }}</p>
</div>
</div>
</section>
<ContactForm />
<ContactMaps />
<FooterCom />
</template>
<script>
import{defineComponent as o,defineAsyncComponent as t}from"vue";import{metaMixin as m}from"@/mixins/metaMixin";let HeaderCom=t(()=>import("@/components/HeaderCom.vue")),FooterCom=t(()=>import("@/components/FooterCom.vue")),ContactForm=t(()=>import("@/components/contact/ContactForm.vue")),ContactMaps=t(()=>import("@/components/contact/ContactMaps.vue"));export default o({name:"ContactUs",components:{FooterCom,HeaderCom,ContactForm,ContactMaps},mixins:[m]});
</script>