<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MISC</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content-header">
<div class="container-fluid">
<div class="row mb-2">
<div class="col-sm-6">
<h1>Data Produk</h1>
</div>
<div class="col-sm-6">
<ol class="breadcrumb float-sm-right">
<li class="breadcrumb-item"><a href="#">Home</a></li>
<li class="breadcrumb-item active">Data Produk</li>
</ol>
</div>
</div>
</div>
</section>
<section class="content">
<div class="container-fluid">
<div class="row">
<div class="col-12">
<div class="card">
<div class="card-header row">
<h3 class="card-title">Data Produk</h3>
<div class="col-12 d-flex justify-content-end">
<router-link to="/TambahProduk" class="btn btn-primary">
<i class="fas fa-plus" style="color:#fff"> Tambah Produk</i>
</router-link>
</div>
</div>
<div class="card-body">
<div class="table-responsive">
<table id="produk" class="table table-bordered table-hover">
<thead>
<tr>
<th scope="col">No</th>
<th scope="col">Nama Produk IN</th>
<th scope="col">Nama Produk EN</th>
<th scope="col">kategori</th>
<th scope="col">Gambar List Porduk</th>
<th scope="col">Options</th>
</tr>
</thead>
<tbody>
<tr v-for="(post, index) in posts" :key="index">
<td>{{ index + 1 }}</td>
<td>{{ post.judul_en }}</td>
<td>{{ post.judul_id }}</td>
<td>{{ post.nama_kategori }}</td>
<td>
<div class="image-container">
<img :src="getFileUrl(post.gambar1)" alt="Deskripsi Gambar" class="custom-img-style">
</div>
</td>
<td class="text-center">
<router-link :to="{ name: 'EditProduk', params: { id_produk: post.id_produk } }" class="btn btn-warning mr-2" style="color:#fff">EDIT</router-link>
<button @click.prevent="confirmDelete(post.id_produk)" class="btn btn-sm btn-danger">DELETE</button>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import e from"axios";import{onMounted as t,ref as r,watch as o,defineAsyncComponent as a,nextTick as s}from"vue";import p from"jquery";import"datatables.net";import"datatables.net-dt/css/jquery.dataTables.min.css";let NavbarDs=a(()=>import("@/components/NavbarDs.vue")),FooterDs=a(()=>import("@/components/FooterDs.vue")),SidebarDs=a(()=>import("@/components/SidebarDs.vue"));export default{name:"TampilanProduk",components:{FooterDs,NavbarDs,SidebarDs},setup(){let a=r([]),d=()=>{e.get("https://puracigarettesolutions.com:3000/api/produk/TampilanProduk").then(e=>{a.value=e.data.data}).catch(e=>{console.log(e)})},i=()=>{s(()=>{let e=p.fn.dataTable.isDataTable("#produk");e&&p("#produk").DataTable().destroy(),p("#produk").DataTable({responsive:!0,scrollX:!0})})},u=e=>`https://puracigarettesolutions.com:3000/api/produk/file/${e}`,l=e=>{confirm("Are you sure you want to delete this product?")&&n(e)},n=t=>{if(!t){console.error("Invalid product ID");return}e.delete(`https://puracigarettesolutions.com:3000/api/produk/TampilanProduk/${t}`).then(()=>{console.log("Product deleted successfully"),d()}).catch(e=>{console.error("Error deleting product:",e.response?e.response.data:"No response received from server"),alert("Failed to delete product. Please try again.")})};return o(a,e=>{e.length&&i()},{immediate:!0}),t(d),{posts:a,getFileUrl:u,confirmDelete:l}}};
</script>
<style src="@/assets/css/dashboard/Produk.css"></style>