<template>
<div class="login-page">
<div class="login-box">
<div class="card card-outline card-primary">
<div class="card-header text-center">
<img src="assets/img/business-4/LOGO_Black.png" width="50px" height="50px">
</div>
<div class="card-body">
<p class="login-box-msg">LOGIN</p>
<form @submit.prevent="login">
<div class="input-group mb-3">
<input v-model="username" type="text" class="form-control" placeholder="Username" required>
<div class="input-group-append">
<div class="input-group-text">
<span class="fas fa-envelope"></span>
</div>
</div>
</div>
<div class="input-group mb-3">
<input v-model="password" type="password" class="form-control" placeholder="Password" required autocomplete="current-password">
<div class="input-group-append">
<div class="input-group-text">
<span class="fas fa-lock"></span>
</div>
</div>
</div>
<div class="row">
<div class="col-4">
<button type="submit" :disabled="!isValidForm || loading" class="form-control btn btn-primary rounded submit px-3">
{{ loading ? 'Signing In...' : 'Sign In' }}
</button>
</div>
</div>
</form>
<br>
</div>
</div>
</div>
</div>
</template>
<script>
import a from"../store";export default{name:"UserLogin",data:()=>({username:"",password:"",errorMessage:"",loading:!1,isValidForm:!1}),watch:{username(){this.validateForm()},password(){this.validateForm()}},methods:{validateForm(){this.isValidForm=!!this.username&&!!this.password},async login(){this.errorMessage="",this.loading=!0;try{let s=await this.$axios.post("https://puracigarettesolutions.com:3000/api/users/login",{username:this.username,password:this.password});s.data.success?(localStorage.setItem("token",s.data.token),a.commit("SET_LOGGED_IN",!0),a.commit("SET_USER_ROLE",s.data.role),this.$router.push({name:"DashboardDS"})):this.errorMessage=s.data.message||"Login gagal"}catch(e){console.error(e),"ECONNABORTED"===e.code?this.errorMessage="Koneksi timeout. Silakan coba lagi.":"Network Error"===e.message?this.errorMessage="Terjadi kesalahan jaringan. Periksa koneksi Anda dan coba lagi.":this.errorMessage=e.response?.data?.message||"Terjadi kesalahan saat login."}finally{this.loading=!1}}}};
</script>
<style src="@/assets/css/dashboard/Loginpage.css"></style>