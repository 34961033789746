<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MISC</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content">
<div class="container-fluid">
<div class="card card-primary card-outline">
<div class="card-header">
<div class="col-6">
<h5>Tulisan Baru</h5>
</div>
<div class="text-right">
<router-link to="/TampilanKategori" class="btn btn-primary" style="color:#fff">
<i class="fas fa-angle-left" style="color:#fff"> </i> Kembali
</router-link>
</div>
</div>
<form @submit.prevent="store" class="card-body">
<div class="form-group">
<label for="postTitle">Nama Kategori:</label>
<input type="text" id="nama_kategoeri" v-model="post.nama_kategori" class="form-control" placeholder="Enter Post Title" required>
</div>
<div class="form-group">
<button type="submit" class="btn btn-primary">Submit</button>
</div>
</form>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import{reactive as e,ref as r}from"vue";import{useRouter as o}from"vue-router";import t from"axios";import{defineAsyncComponent as a}from"vue";let NavbarDs=a(()=>import("@/components/NavbarDs.vue")),FooterDs=a(()=>import("@/components/FooterDs.vue")),SidebarDs=a(()=>import("@/components/SidebarDs.vue"));export default{name:"TambahKategori",components:{FooterDs,NavbarDs,SidebarDs},setup(){let a=e({nama_kategori:""}),s=r([]),p=o();return{post:a,validationErrors:s,store:function e(){let{nama_kategori:r}=a;t.post("https://puracigarettesolutions.com:3000/api/kategori/CreateKategori",{nama_kategori:r}).then(()=>{p.push("/TampilanKategori")}).catch(e=>{var r;r=e,r.response&&r.response.data?s.value=r.response.data:(console.error("Unexpected error structure:",r),s.value=[{param:"error",msg:"Unexpected error occurred"}])})}}}};
</script>
