<template>
<link rel="preload" as="image" href="assets/img/business-4/slider/about_low.webp" importance="high">
<a href="#" class="apbc-scroll-top" aria-label="Scroll to top">
<i class="fas fa-angle-double-up"></i>
</a>
<HeaderCom />
<section class="saasio-breadcurmb-section" :class="{ 'loaded': isHighResLoaded }" ref="breadcrumbSection">
<div class="container">
<div class="breadcurmb-title">
<h1><strong>{{ $t('aboutPage.title') }}</strong></h1>
<p class="text-base">
{{ $t('aboutPage.content') }}
<a href="https://www.puragroup.com/en/home/" class="text-blue-600 hover:text-blue-800">
Pura Grup
</a>
{{ $t('aboutPage.content1') }}
</p>
<h4>{{ $t('aboutPage.subtitle') }}</h4>
<p>{{ $t('aboutPage.content2') }}</p>
<p>{{ $t('aboutPage.content3') }}</p>
<p>{{ $t('aboutPage.content4') }}</p>
<p>{{ $t('aboutPage.more') }}</p>
</div>
</div>
</section>
<StoryTimeline />
<CoreValues />
<QuotesSection />
<StatisticsSection />
<BannerSection />
<FacilitiesSection />
<Certificate />
<PatentSectin />
<FooterCom />
</template>
<script>
import{defineAsyncComponent as e,ref as t,onMounted as o}from"vue";import{useI18n as i}from"vue-i18n";import{metaMixin as n}from"@/mixins/metaMixin";let HeaderCom=e(()=>import("@/components/HeaderCom.vue")),FooterCom=e(()=>import("@/components/FooterCom.vue")),StoryTimeline=e(()=>import("@/components/about/StoryTimelineSection.vue")),CoreValues=e(()=>import("@/components/about/CoreValuesSection.vue")),QuotesSection=e(()=>import("@/components/about/QuotesSection.vue")),StatisticsSection=e(()=>import("@/components/about/StatisticsSection.vue")),BannerSection=e(()=>import("@/components/about/BannerSection.vue")),FacilitiesSection=e(()=>import("@/components/about/FacilitiesSection.vue")),PatentSectin=e(()=>import("@/components/PatentSection.vue")),Certificate=e(()=>import("@/components/Certificate.vue"));export default{name:"AboutPage",mixins:[n],components:{HeaderCom,FooterCom,StoryTimeline,CoreValues,QuotesSection,StatisticsSection,BannerSection,FacilitiesSection,PatentSectin,Certificate},setup(){let{t:e}=i(),n=t(!1),m=t(null),r=()=>{let e=new Image;e.src="assets/img/business-4/slider/about.webp",e.onload=()=>{n.value=!0}};return o(()=>{r()}),{t:e,isHighResLoaded:n,breadcrumbSection:m}}};
</script>