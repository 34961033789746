<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MICS</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content">
<div class="container-fluid">
<div class="card card-primary card-outline">
<div class="card-header">
<div class="col-6">
<h5>Tulisan Baru</h5>
</div>
<div class="text-right">
<router-link to="/TampilanSosmed" class="btn btn-primary" style="color:#fff">
<i class="fas fa-angle-left" style="color:#fff"></i> Kembali
</router-link>
</div>
</div>
<form @submit.prevent="update" class="card-body">
<div class="form-group">
<label for="postTitle">Title IN:</label>
<input type="text" id="judul_id" v-model="post.judul_id" class="form-control" placeholder="Enter Post Title" required>
</div>
<div class="form-group">
<label for="postTitle">Title EN:</label>
<input type="text" id="judul_en" v-model="post.judul_en" class="form-control" placeholder="Enter Post Title" required>
</div>
<div class="form-group">
<label for="author">URL:</label>
<input type="text" id="url" v-model="post.url" class="form-control" required>
</div>
<div class="form-group">
<label for="imageUpload">Image:</label>
<input type="file" @change="handleFileUpload" accept="image/*" />
<div class="image-container">
<img :src="getFileUrl(post.gambar)" alt="Deskripsi Gambar" class="custom-img-style">
</div>
<img v-if="post.gambar" :src="post.gambar" height="50" width="50" alt="Image Preview" />
</div>
<div class="form-group">
<button type="submit" class="btn btn-primary">Submit</button>
</div>
</form>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import{reactive as e,defineAsyncComponent as r,onMounted as t}from"vue";import a from"axios";import{useRouter as o,useRoute as s}from"vue-router";let NavbarDs=r(()=>import("@/components/NavbarDs.vue")),FooterDs=r(()=>import("@/components/FooterDs.vue")),SidebarDs=r(()=>import("@/components/SidebarDs.vue"));export default{components:{FooterDs,NavbarDs,SidebarDs},setup(){let r=e({judul_id:"",judul_en:"",url:"",gambar:""}),u=o(),p=s();t(async()=>{if(!p.params.id){console.error("No post ID provided in route parameters.");return}try{let e=await a.get(`https://puracigarettesolutions.com:3000/api/sosmed/${p.params.id}`);if(e.data.success&&e.data.data){let{judul_id:t,judul_en:o,url:s,gambar:u}=e.data.data;r.judul_id=t||"",r.judul_en=o||"",r.url=s||"",r.gambar=u||""}else console.error("Invalid or missing data in server response.")}catch(d){console.error("Error fetching post data:",d)}});let d=e=>{let t=e.target.files[0];t&&(r.gambar=URL.createObjectURL(t))},i=async()=>{try{let e=new FormData;e.append("judul_id",r.judul_id),e.append("judul_en",r.judul_en),e.append("url",r.url),e.append("gambar",r.gambar);let t=await a.put(`https://puracigarettesolutions.com:3000/api/sosmed/update/${p.params.id}`,e);if(200===t.status)u.push("/TampilanSosmed"),console.log("Post updated successfully");else throw Error("Failed to update post")}catch(o){console.error("Error updating post:",o)}};return{post:r,handleFileUpload:d,getFileUrl:e=>e||"",update:i}}};
</script>
<style src="@/assets/css/dashboard/Image.css"></style>