<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MISC</span>
</a>
<SidebarDs />
</aside>
<div class="login-page">
<div class="login-box">
<div class="card card-outline card-primary">
<div class="card-body">
<p class="login-box-msg">REGISTRASI</p>
<form @submit.prevent="register">
<div class="input-group mb-3">
<input v-model="username" type="text" class="form-control" placeholder="Username" required>
<div class="input-group-append">
<div class="input-group-text">
<span class="fas fa-envelope"></span>
</div>
</div>
</div>
<div class="input-group mb-3">
<input v-model="password" type="password" class="form-control" placeholder="Password" required>
<div class="input-group-append">
<div class="input-group-text">
<span class="fas fa-lock"></span>
</div>
</div>
</div>
<div class="input-group mb-3">
<input v-model="confirmPassword" type="password" class="form-control" placeholder="Confirm Password" required>
<div class="input-group-append">
<div class="input-group-text">
<span class="fas fa-lock"></span>
</div>
</div>
</div>
<div class="row">
<div class="col-4">
<button :disabled="loading" type="submit" class="form-control btn btn-primary rounded submit px-3">
{{ loading ? 'Registering...' : 'Register' }}
</button>
</div>
</div>
</form>
<br>
<p class="mb-0">
<a href="register.html" class="text-center">Register a new membership</a>
</p>
</div>
</div>
</div>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import s from"axios";import{defineAsyncComponent as r}from"vue";let NavbarDs=r(()=>import("@/components/NavbarDs.vue")),FooterDs=r(()=>import("@/components/FooterDs.vue")),SidebarDs=r(()=>import("@/components/SidebarDs.vue"));export default{name:"UserRegistration",components:{FooterDs,NavbarDs,SidebarDs},data:()=>({username:"",password:"",confirmPassword:"",id_role:"",successMessage:"",errorMessage:"",loading:!1}),methods:{register(){if(!this.username||!this.password||!this.confirmPassword){this.errorMessage="Username, password, and confirm password are required.";return}if(this.password!==this.confirmPassword){this.errorMessage="Password and confirm password do not match.";return}this.loading=!0,s.post("https://puracigarettesolutions.com:3000/api/users/register",{username:this.username,password:this.password}).then(s=>{s.data.success?(this.successMessage=s.data.message,this.$router.push("/DashboardDs")):this.errorMessage=s.data.message||"Registration failed.",this.loading=!1}).catch(s=>{console.error("Registration error:",s.response.data),this.errorMessage="Error during registration.",this.loading=!1})}}};
</script>
<style src="@/assets/css/dashboard/Loginpage.css"></style>
  