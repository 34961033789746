<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MISC</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content">
<div class="container-fluid">
<div class="card card-primary card-outline">
<div class="card-header">
<div class="col-6">
<h5>Tulisan Baru</h5>
</div>
<div class="text-right">
<router-link to="/TampilanKategori" class="btn btn-primary" style="color:#fff">
<i class="fas fa-angle-left" style="color:#fff"> </i> Kembali
</router-link>
</div>
</div>
<form @submit.prevent="update" class="card-body">
<div class="form-group">
<label for="postTitle">Nama Kategori:</label>
<input type="text" id="nama_kategoeri" v-model="post.nama_kategori" class="form-control" placeholder="Enter Post Title" required>
</div>
<div class="form-group">
<button type="submit" class="btn btn-primary">Submit</button>
</div>
</form>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import{reactive as a,ref as r,defineAsyncComponent as t,onMounted as e}from"vue";import{useRouter as o,useRoute as i}from"vue-router";import s from"axios";let NavbarDs=t(()=>import("@/components/NavbarDs.vue")),FooterDs=t(()=>import("@/components/FooterDs.vue")),SidebarDs=t(()=>import("@/components/SidebarDs.vue"));export default{name:"EditKategori",components:{FooterDs,NavbarDs,SidebarDs},setup(){let t=a({id_kategori:"",nama_kategori:""}),n=r({errors:{}}),d=o(),p=i();e(async()=>{if(!p.params.id_kategori){console.error("No category ID provided in route parameters.");return}try{let a=await s.get(`https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori/${p.params.id_kategori}`);console.log("Response from server:",a.data),a.data.success&&a.data.data&&"object"==typeof a.data.data?(t.id_kategori=a.data.data.id_kategori||"",t.nama_kategori=a.data.data.nama_kategori||""):(console.error("Invalid or missing data in server response."),t.id_kategori="",t.nama_kategori="")}catch(r){console.error("Error fetching category data:",r),r.response,t.id_kategori="",t.nama_kategori=""}});let g=async()=>{try{await s.put(`https://puracigarettesolutions.com:3000/api/kategori/EditKategori/${t.id_kategori}`,{nama_kategori:t.nama_kategori}),d.push("/TampilanKategori"),console.log("Category updated successfully!")}catch(a){n.value.errors=a.response.data}};return{post:t,validation:n,update:g}}};
</script>
  

