<template>
<link rel="preload" as="image" href="/assets/img/business-4/slider/PRODUCT_LOW.webp" type="image/webp" fetchpriority="high">
<a href="#" class="apbc-scroll-top" v-show="showScrollTop" aria-label="Scroll to top">
<i class="fas fa-angle-double-up"></i>
</a>
<HeaderCom />
<section class="saasio-breadcurmb-section-produk" :class="{ 'loaded': isImageLoaded }" :style="heroBackgroundStyle">
<div class="container">
<div class="breadcurmb-title-produk">
<h1>{{ $t('ProdukPages.title') }}</h1>
<p>{{ $t('ProdukPages.subtitle') }}</p>
</div>
</div>
</section>
<ProductSection />
<InnovationSection />
<ScrollToFooterButton />
<FooterCom />
</template>
<script>
import{defineAsyncComponent as o}from"vue";import{metaMixin as e}from"@/mixins/metaMixin";let HeaderCom=o(()=>import("@/components/HeaderCom.vue")),FooterCom=o(()=>import("@/components/FooterCom.vue")),ProductSection=o(()=>import("@/components/Product/ProductSection.vue")),InnovationSection=o(()=>import("@/components/Product/InnovationSection.vue")),ScrollToFooterButton=o(()=>import("@/components/ScrollToFooterButton.vue"));export default{name:"ProductHeroSection",mixins:[e],components:{HeaderCom,ProductSection,InnovationSection,ScrollToFooterButton,FooterCom},data:()=>({isImageLoaded:!1,heroBackgroundStyle:{backgroundImage:"url(/assets/img/business-4/slider/PRODUCT_LOW.webp)",backgroundColor:"#f5f5f5"}}),mounted(){let o=new Image;o.onload=()=>{this.heroBackgroundStyle.backgroundImage="url(/assets/img/business-4/slider/PRODUCT.webp)",this.isImageLoaded=!0},o.src="/assets/img/business-4/slider/PRODUCT.webp"}};
</script>