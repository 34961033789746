<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MISC</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content-header">
<div class="container-fluid">
<div class="row mb-2">
<div class="col-sm-6">
<h1>Data Produk</h1>
</div>
<div class="col-sm-6">
<ol class="breadcrumb float-sm-right">
<li class="breadcrumb-item"><a href="#">Home</a></li>
<li class="breadcrumb-item active">Data Produk</li>
</ol>
</div>
</div>
</div>
</section>
<section class="content">
<div class="container-fluid">
<div class="row">
<div class="col-12">
<div class="card">
<div class="card-header row">
<h3 class="card-title">Data Produk</h3>
<div class="col-12 d-flex justify-content-end">
<router-link to="/TambahDetailProduk" class="btn btn-primary">
<i class="fas fa-plus" style="color:#fff"> Tambah Detail Produk</i>
</router-link>
</div>
</div>
<div class="card-body">
<div class="table-responsive">
<table id="detailproduk" class="table table-bordered table-hover">
<thead>
<tr>
<th scope="col">No</th>
<th scope="col">Nama Detail Produk EN</th>
<th scope="col">Nama Detail Produk ID</th>
<th scope="col">Produk</th>
<th scope="col">Gambar</th>
<th scope="col">Options</th>
</tr>
</thead>
<tbody>
<tr v-for="(post, index) in posts" :key="index">
<td>{{ index + 1 }}</td>
<td>{{ post.nama_detail_produk_en }}</td>
<td>{{ post.nama_detail_produk_id }}</td>
<td>{{ post.judul_en }}</td>
<td>
<div class="image-container">
<img :src="getFileUrl(post.gambar)" alt="Deskripsi Gambar" class="custom-img-style">
</div>
</td>
<td class="text-center">
<router-link :to="{ name: 'EditDetailProduk', params: { id_detail_produk: post.id_detail_produk } }" class="btn btn-warning mr-2" style="color:#fff">EDIT</router-link>
<button @click.prevent="confirmDelete(post.id_detail_produk)" class="btn btn-sm btn-danger">DELETE</button>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import t from"axios";import{onMounted as e,defineAsyncComponent as a,ref as o}from"vue";import{nextTick as r}from"vue";import s from"jquery";import"datatables.net";import"datatables.net-dt/css/jquery.dataTables.min.css";let NavbarDs=a(()=>import("@/components/NavbarDs.vue")),FooterDs=a(()=>import("@/components/FooterDs.vue")),SidebarDs=a(()=>import("@/components/SidebarDs.vue"));export default{name:"TampilanProduk",components:{FooterDs,NavbarDs,SidebarDs},setup(){let a=o([]),i=()=>{t.get("https://puracigarettesolutions.com:3000/api/detailproduk/TampilanDetailProduk").then(t=>{a.value=t.data.data,r(()=>{s("#detailproduk").DataTable({responsive:!0})})}).catch(t=>{console.log(t)})},p=t=>`https://puracigarettesolutions.com:3000/api/detailproduk/file/${t}`,u=t=>{confirm("Are you sure you want to delete this post?")&&d(t)},d=e=>{t.delete(`https://puracigarettesolutions.com:3000/api/detailproduk/TampilanDetailProduk/${e}`).then(()=>{i()}).catch(t=>{console.error("Error deleting post:",t.response.data)})};return e(i),{posts:a,getFileUrl:p,confirmDelete:u}}};
</script>