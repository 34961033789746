<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MISC</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content-header">
<div class="container-fluid">
<div class="row mb-2">
<div class="col-sm-6">
<h1>Tampilan Artikel</h1>
</div>
<div class="col-sm-6">
<ol class="breadcrumb float-sm-right">
<li class="breadcrumb-item"><a href="#">Home</a></li>
<li class="breadcrumb-item active">Tampilan Artikel</li>
</ol>
</div>
</div>
</div>
</section>
<section class="content">
<div class="container-fluid">
<div class="row">
<div class="col-12">
<div class="card">
<div class="card-header row">
<h3 class="card-title">Data Artikel</h3>
<div class="col-12 d-flex justify-content-end">
<router-link to="/TambahArtikel" class="btn btn-primary">
<i class="fas fa-plus" style="color:#fff"> Tambah Artikel</i>
</router-link>
</div>
</div>
<div class="card-body">
<div class="table-responsive">
<table id="example2" class="table table-bordered table-hover">
<thead>
<tr>
<th scope="col">No</th>
<th scope="col">Judul EN</th>
<th scope="col">Judul ID</th>
<th scope="col">kategori</th>
<th scope="col">Tanggal</th>
<th scope="col">Deskripsi EN</th>
<th scope="col">Deskripsi ID</th>
<th scope="col">Keterangan EN</th>
<th scope="col">Keterangan ID</th>
<th scope="col">Pembuat</th>
<th scope="col">Referensi 1</th>
<th scope="col">Referensi 2</th>
<th scope="col">Referensi 3</th>
<th scope="col">Referensi 4</th>
<th scope="col">Gambar Untuk Home</th>
<th scope="col">Gambar Untuk List Produk</th>
<th scope="col">Gambar Untuk Detail Produk</th>
<th scope="col">OPTIONS</th>
</tr>
</thead>
<tbody>
<tr v-for="(post, index) in posts" :key="index">
<td>{{ index + 1 }}</td>
<td>{{ post.judul_en }}</td>
<td>{{ post.judul_id }}</td>
<td>{{ post.nama_kategori }}</td>
<td>{{ post.tanggal }}</td>
<td>{{ post.deskripsi_en }}</td>
<td>{{ post.deskripsi_id }}</td>
<td>{{ post.keterangan_en }}</td>
<td>{{ post.keterangan_id }}</td>
<td>{{ post.pembuat }}</td>
<td>{{ post.link_ref1 }}</td>
<td>{{ post.link_ref2 }}</td>
<td>{{ post.link_ref3 }}</td>
<td>{{ post.link_ref4 }}</td>
<td>
<div class="image-container">
<img :src="getFileUrl(post.gambar1)" alt="Deskripsi Gambar" class="custom-img-style">
</div>
</td>
<td>
<div class="image-container">
<img :src="getFileUrl(post.gambar2)" alt="Deskripsi Gambar" class="custom-img-style">
</div>
</td>
<td>
<div class="image-container">
<img :src="getFileUrl(post.gambar3)" alt="Deskripsi Gambar" class="custom-img-style">
</div>
</td>
<td class="text-center">
<router-link :to="{ name: 'EditArtikel', params: { id_artikel: post.id_artikel } }" class="btn btn-warning mr-2" style="color:#fff">EDIT</router-link>
<button @click.prevent="confirmDelete(post.id_artikel)" class="btn btn-sm btn-danger">DELETE</button>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import t from"axios";import{onMounted as e,defineAsyncComponent as a,ref as o}from"vue";import{nextTick as r}from"vue";import s from"jquery";import"datatables.net";import"datatables.net-dt/css/jquery.dataTables.min.css";import i from"moment";let NavbarDs=a(()=>import("@/components/NavbarDs.vue")),FooterDs=a(()=>import("@/components/FooterDs.vue")),SidebarDs=a(()=>import("@/components/SidebarDs.vue"));export default{name:"TampilanArtikel",components:{FooterDs,NavbarDs,SidebarDs},setup(){let a=o([]),p=()=>{t.get("https://puracigarettesolutions.com:3000/api/artikel/TampilanArtikel").then(t=>{a.value=t.data.data.map(t=>({...t,tanggal:i(t.tanggal).format("YYYY-MM-DD")})),r(()=>{s("#example2").DataTable({responsive:!0,scrollX:!0})})}).catch(t=>{console.log(t)})},m=t=>`https://puracigarettesolutions.com:3000/api/artikel/file/${t}`,l=t=>{confirm("Are you sure you want to delete this post?")&&n(t)},n=e=>{t.delete(`https://puracigarettesolutions.com:3000/api/artikel/TampilanArtikel/${e}`).then(()=>{p()}).catch(t=>{console.error("Error deleting post:",t.response.data)})};return e(p),{posts:a,getFileUrl:m,confirmDelete:l}}};
</script>
<style src="@/assets/css/dashboard/Artikel.css"></style>