<template>
<HeaderCom />
<div class="breadcurmb-title-sample">
<h1 class="critical-render">{{ $t('Sampel.subtitle') }}</h1>
<p class="critical-render">{{ $t('Sampel.texttitle') }}</p>
</div>
<TimelineSection />
<SampleForm />
<FooterCom />
</template>
<script>
import{defineAsyncComponent as e}from"vue";import{metaMixin as o}from"@/mixins/metaMixin";let HeaderCom=e(()=>import("@/components/HeaderCom")),FooterCom=e(()=>import("@/components/FooterCom")),TimelineSection=e(()=>import("@/components/sample/TimelineSection.vue")),SampleForm=e(()=>import("@/components/sample/SampleForm.vue"));export default{name:"SamplePage",components:{HeaderCom,FooterCom,TimelineSection,SampleForm},mixins:[o]};
</script>