<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MISC</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<div class="content-header">
<div class="container-fluid">
<div class="row mb-2">
<div class="col-sm-6">
<h1 class="m-0">Dashboard</h1>
</div>
<div class="col-sm-6">
<ol class="breadcrumb float-sm-right">
<li class="breadcrumb-item"><a href="#">Home</a></li>
<li class="breadcrumb-item active">Dashboard</li>
</ol>
</div>
</div>
</div>
</div>
<section class="content">
<div class="container-fluid">
<div class="row">
<div class="col-lg-3 col-6">
<div class="small-box bg-info">
<div class="inner">
<h3>{{ JumlahArtikel }}</h3>
<p>Jumlah Artikel</p>
</div>
<div class="icon">
<i class="ion ion-bag"></i>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="small-box bg-success">
<div class="inner">
<h3>{{ JumlahProduk }}</h3>
<p>Jumlah Produk</p>
</div>
<div class="icon">
<i class="ion ion-stats-bars"></i>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="small-box bg-danger">
<div class="inner">
<h3>{{ JumlahDetailProduk }}</h3>
<p>Jumlah Detail Produk</p>
</div>
<div class="icon">
<i class="ion ion-stats-bars"></i>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="small-box bg-warning">
<div class="inner">
<h3>{{ JumlahKategori }}</h3>
<p>Jumlah Ketegeri</p>
</div>
<div class="icon">
<i class="ion ion-person-add"></i>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="small-box bg-info">
<div class="inner">
<h3>{{ JumlahEtiket }}</h3>
<p>Jumlah Permintaan Sampel CTP</p>
</div>
<div class="icon">
<i class="ion ion-person-add"></i>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="small-box bg-danger">
<div class="inner">
<h3>{{ JumlahCtp }}</h3>
<p>Jumlah Permintaan Sampel Etiket</p>
</div>
<div class="icon">
<i class="ion ion-person-add"></i>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="small-box bg-info">
<div class="inner">
<h3>{{ JumlahCp }}</h3>
<p>Jumlah Permintaan Sampel Cigarette Paper</p>
</div>
<div class="icon">
<i class="ion ion-person-add"></i>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="small-box bg-warning">
<div class="inner">
<h3>{{ JumlahFilter }}</h3>
<p>Jumlah Permintaan Sampel Filter Tips</p>
</div>
<div class="icon">
<i class="ion ion-person-add"></i>
</div>
</div>
</div>
<div class="col-lg-3 col-6">
<div class="small-box bg-success">
<div class="inner">
<h3>{{ JumlahPr }}</h3>
<p>Jumlah Permintaan Sampel Pre-Rolled Cones</p>
</div>
<div class="icon">
<i class="ion ion-person-add"></i>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import{defineAsyncComponent as a}from"vue";let NavbarDs=a(()=>import("@/components/NavbarDs.vue")),FooterDs=a(()=>import("@/components/FooterDs.vue")),SidebarDs=a(()=>import("@/components/SidebarDs.vue"));export default{name:"DashboardDs",components:{FooterDs,NavbarDs,SidebarDs},data:()=>({JumlahArtikel:0,JumlahProduk:0,JumlahDetailProduk:0,JumlahKategori:0,JumlahEtiket:0,JumlahCtp:0,JumlahCp:0,JumlahFilter:0,JumlahPr:0}),created(){this.getJumlahData()},methods:{async getJumlahData(){try{let a=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahArtikel"),t=await a.json();this.JumlahArtikel=t.data.total;let o=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahProduk"),i=await o.json();this.JumlahProduk=i.data.total;let s=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahDetailProduk"),e=await s.json();this.JumlahDetailProduk=e.data.total;let l=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahKategori"),r=await l.json();this.JumlahKategori=r.data.total;let h=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahEtiket"),u=await h.json();this.JumlahEtiket=u.data.total;let m=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahCtp"),d=await m.json();this.JumlahCtp=d.data.total;let p=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahCtp"),$=await p.json();this.JumlahCp=$.data.total;let n=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahFilter"),J=await n.json();this.JumlahFilter=J.data.total;let c=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahPr"),w=await c.json();this.JumlahPr=w.data.total}catch(b){console.error("Terjadi kesalahan:",b)}}}};
</script>