<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MICS</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content-header">
<div class="container-fluid">
<div class="row mb-2">
<div class="col-sm-6">
<h1>Data Kategori</h1>
</div>
<div class="col-sm-6">
<ol class="breadcrumb float-sm-right">
<li class="breadcrumb-item"><a href="#">Home</a></li>
<li class="breadcrumb-item active">Data Kategori</li>
</ol>
</div>
</div>
</div>
</section>
<section class="content">
<div class="container-fluid">
<div class="row">
<div class="col-12">
<div class="card">
<div class="card-header row">
<h3 class="card-title">Data Kategori</h3>
<div class="col-12 d-flex justify-content-end">
<router-link to="/TambahKategori" class="btn btn-primary">
<i class="fas fa-plus" style="color:#fff"> Tambah Kategori</i>
</router-link>
</div>
</div>
<div class="card-body">
<table id="kategori" class="table table-bordered table-hover">
<thead>
<tr>
<th scope="col">No</th>
<th scope="col">Nama Kategori</th>
<th scope="col">OPTIONS</th>
</tr>
</thead>
<tbody>
<tr v-for="(post, index) in posts" :key="index">
<td>{{ index + 1 }}</td>
<td>{{ post.nama_kategori }}</td>
<td class="text-center">
<router-link :to="{ name: 'EditKategori', params: { id_kategori: post.id_kategori } }" class="btn btn-warning mr-2" style="color:#fff">EDIT</router-link>
<button @click.prevent="confirmDelete(post.id_kategori)" class="btn btn-sm btn-danger">DELETE</button>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import t from"axios";import{onMounted as e,defineAsyncComponent as o,ref as a}from"vue";import{nextTick as r}from"vue";import s from"jquery";import"datatables.net";import"datatables.net-dt/css/jquery.dataTables.min.css";let NavbarDs=o(()=>import("@/components/NavbarDs.vue")),FooterDs=o(()=>import("@/components/FooterDs.vue")),SidebarDs=o(()=>import("@/components/SidebarDs.vue"));export default{name:"LoginDs",components:{FooterDs,NavbarDs,SidebarDs},setup(){let o=a([]),i=()=>{t.get("https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori").then(t=>{o.value=t.data.data,r(()=>{s("#kategori").DataTable({})})}).catch(t=>{console.log(t)})},p=t=>{confirm("Are you sure you want to delete this post?")&&m(t)},m=e=>{t.delete(`https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori/${e}`).then(()=>{i()}).catch(t=>{console.error("Error deleting post:",t.response.data)})};return e(i),{posts:o,confirmDelete:p}}};
</script>