<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MICS</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content-header">
<div class="container-fluid">
<div class="row mb-2">
<div class="col-sm-6">
<h1>SOSMED</h1>
</div>
<div class="col-sm-6">
<ol class="breadcrumb float-sm-right">
<li class="breadcrumb-item"><a href="#">Home</a></li>
<li class="breadcrumb-item active">SOSMED</li>
</ol>
</div>
</div>
</div>
</section>
<section class="content">
<div class="container-fluid">
<div class="row">
<div class="col-12">
<div class="card">
<div class="card-header row">
<h3 class="card-title">Data Sosmed</h3>
<div class="col-12 d-flex justify-content-end">
<router-link to="/TambahSosmed" class="btn btn-primary">
<i class="fas fa-plus" style="color:#fff"> Tambah Sosmed</i>
</router-link>
</div>
</div>
<div class="card-body">
<div class="table-responsive">
<table id="sosmed" class="table table-bordered table-hover">
<thead>
<tr>
<th scope="col">No</th>
<th scope="col">Judul EN</th>
<th scope="col">Judul ID</th>
<th scope="col">URL</th>
<th scope="col">Gambar</th>
<th scope="col">Options</th>
</tr>
</thead>
<tbody>
<tr v-for="(post, index) in posts" :key="index">
<td>{{ index + 1 }}</td>
<td>{{ post.judul_en }}</td>
<td>{{ post.judul_id }}</td>
<td>{{ post.url }}</td>
<td>
<div class="image-container">
<img :src="getFileUrl(post.gambar)" alt="Deskripsi Gambar" class="custom-img-style">
</div>
</td>
<td class="text-center">
<router-link :to="{ name: 'EditSosmed', params: { id_sosmed: post.id_sosmed } }" class="btn btn-warning mr-2" style="color:#fff">EDIT</router-link>
<button @click.prevent="confirmDelete(post.id_sosmed)" class="btn btn-sm btn-danger">DELETE</button>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import e from"axios";import{onMounted as t,defineAsyncComponent as o,ref as s}from"vue";import{nextTick as a}from"vue";import r from"jquery";import"datatables.net";import"datatables.net-dt/css/jquery.dataTables.min.css";let NavbarDs=o(()=>import("@/components/NavbarDs.vue")),FooterDs=o(()=>import("@/components/FooterDs.vue")),SidebarDs=o(()=>import("@/components/SidebarDs.vue"));export default{name:"TampilanProduk",components:{FooterDs,NavbarDs,SidebarDs},setup(){let o=s([]),m=()=>{e.get("https://puracigarettesolutions.com:3000/api/sosmed/TampilanSosmed").then(e=>{o.value=e.data.data,a(()=>{r("#sosmed").DataTable({responsive:!0,scrollX:!0})})}).catch(e=>{console.log(e)})},p=e=>`https://puracigarettesolutions.com:3000/api/sosmed/file/${e}`,i=e=>{confirm("Are you sure you want to delete this post?")&&n(e)},n=t=>{e.delete(`https://puracigarettesolutions.com:3000/api/sosmed/TampilanSosmed/${t}`).then(()=>{m()}).catch(e=>{console.error("Error deleting post:",e.response.data)})};return t(m),{posts:o,getFileUrl:p,confirmDelete:i}}};
</script>
<style src="@/assets/css/dashboard/Produk.css"></style>