<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">PURA MICS</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content-header">
<div class="container-fluid">
<div class="row mb-2">
<div class="col-sm-6">
<h1>Data Kategori</h1>
</div>
<div class="col-sm-6">
<ol class="breadcrumb float-sm-right">
<li class="breadcrumb-item"><a href="#">Home</a></li>
<li class="breadcrumb-item active">Data Kategori</li>
</ol>
</div>
</div>
</div>
</section>
<section class="content">
<div class="container-fluid">
<div class="row">
<div class="col-12">
<div class="card">
<div class="card-header row">
<h3 class="card-title">Data Users</h3>
<div class="col-12 d-flex justify-content-end">
<router-link to="/REgisterDs" class="btn btn-primary">
<i class="fas fa-plus" style="color:#fff"> Tambah Users</i>
</router-link>
</div>
</div>
<div class="card-body">
<table id="users" class="table table-bordered table-hover">
<thead>
<tr>
<th scope="col">No</th>
<th scope="col">Username</th>
<th scope="col">Password</th>
<th scope="col">OPTIONS</th>
</tr>
</thead>
<tbody>
<tr v-for="(post, index) in posts" :key="index">
<td>{{ index + 1 }}</td>
<td>{{ post.username }}</td>
<td>{{ post.decodedPassword}}</td>
<td class="text-center">
<button @click.prevent="confirmDelete(post.id_users)" class="btn btn-sm btn-danger">DELETE</button>
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import{defineAsyncComponent as a}from"vue";let NavbarDs=a(()=>import("@/components/NavbarDs.vue")),FooterDs=a(()=>import("@/components/FooterDs.vue")),SidebarDs=a(()=>import("@/components/SidebarDs.vue"));export default{name:"DashboardDs",components:{FooterDs,NavbarDs,SidebarDs},data:()=>({JumlahArtikel:0,JumlahProduk:0,JumlahDetailProduk:0,JumlahKategori:0,JumlahEtiket:0,JumlahCtp:0,JumlahCp:0,JumlahFilter:0,JumlahPr:0}),created(){this.getJumlahData()},methods:{async getJumlahData(){try{let a=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahArtikel"),t=await a.json();this.JumlahArtikel=t.data.total;let o=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahProduk"),i=await o.json();this.JumlahProduk=i.data.total;let s=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahDetailProduk"),e=await s.json();this.JumlahDetailProduk=e.data.total;let l=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahKategori"),r=await l.json();this.JumlahKategori=r.data.total;let h=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahEtiket"),u=await h.json();this.JumlahEtiket=u.data.total;let m=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahCtp"),d=await m.json();this.JumlahCtp=d.data.total;let p=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahCtp"),$=await p.json();this.JumlahCp=$.data.total;let n=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahFilter"),J=await n.json();this.JumlahFilter=J.data.total;let c=await fetch("https://puracigarettesolutions.com:3000/api/dashboard/JumlahPr"),w=await c.json();this.JumlahPr=w.data.total}catch(b){console.error("Terjadi kesalahan:",b)}}}};
</script>