<template>
 <div id="menu-header">
<header :class="['apbc-header-section', {'apbc-header-sticky': isSticky}]">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-2 col-md-6 col-7">
<div class="apbc-logo-wrapper">
<a href="/">
<img :src="logoPath" :alt="$t('header.logo')" class="logo-image" width="150" height="30" loading="eager" fetchpriority="high" decoding="async">
</a>
</div>
</div>
<div class="col-lg-8 apbc-desktop-menu">
<nav class="apbc-nav-menu">
<ul>
<li v-for="item in menuItems" :key="item.section" :class="{ 'active': isActiveRoute(item.path) }">
<a v-if="item.path === '/'" href="/" @click="handleNavigation($event, item.path)" class="nav-link">
{{ $t(`header.${item.section}`) }}
</a>
<RouterLink v-else :to="item.path" @click="closeMenu" class="nav-link">
{{ $t(`header.${item.section}`) }}
</RouterLink>
</li>
</ul>
</nav>
</div>
<div class="col-lg-2 col-md-6 col-5 d-flex justify-content-end align-items-center">
<div class="apbc-lang-menu d-none d-lg-flex">
<ul class="d-flex align-items-center">
<li v-for="lang in languages" :key="lang" class="language-switcher">
<button :class="{ 'selected': locale === lang }" @click="changeLanguage(lang)">
{{ lang.toUpperCase() }}
</button>
<span v-if="lang === 'en'" class="separator-header">|</span>
</li>
</ul>
</div>
<button class="apbc-mobile-menu-btn d-lg-none" @click="toggleMobileMenu" aria-label="Toggle menu">
<i class="fas fa-bars"></i>
</button>
</div>
</div>
</div>
</header>
<div class="apbc-mobile-navigation" :class="{ 'open': isMobileMenuOpen }">
<div class="apbc-mobile-menu-wrapper">
<button class="apbc-mobile-menu-close" @click="toggleMobileMenu" aria-label="Close menu">
<i class="fas fa-times"></i>
</button>
<nav class="mobile-nav">
<ul class="mobile-menu-list">
<li v-for="item in menuItems" :key="item.section" :class="{ 'active': isActiveRoute(item.path) }">
<RouterLink :to="item.path" @click="closeMenu" class="nav-link">
{{ $t(`header.${item.section}`) }}
</RouterLink>
</li>
</ul>
<div class="mobile-lang-menu">
<ul class="d-flex align-items-center justify-content-center">
<li v-for="lang in languages" :key="lang" class="language-switcher">
<button :class="{ 'selected': locale === lang }" @click="changeLanguage(lang)">
{{ lang.toUpperCase() }}
</button>
<span v-if="lang === 'en'" class="separator-header">|</span>
</li>
</ul>
</div>
</nav>
</div>
</div>
</div>
</template>
<script>
import{defineComponent as e,ref as o,computed as t,onMounted as a,onUnmounted as r}from"vue";import{useI18n as l}from"vue-i18n";import{useRouter as n}from"vue-router";let LANGUAGES=["en","id"],MENU_ITEMS=[{section:"home",path:"/"},{section:"about",path:"/AboutPage"},{section:"product",path:"/ProdukPage"},{section:"blog",path:"/BlogPura"},{section:"contactus",path:"/ContactUs"}];export default e({name:"HeaderCom",setup(){let{locale:e}=l(),s=n(),u=o(!1),i=o(!1),c=o(!1),v=t(()=>u.value?"/assets/img/business-4/LOGO_Black.webp":"/assets/img/business-4/LOGO.webp"),g=t(()=>"/"===s.currentRoute.value.path),p=e=>{let o=s.currentRoute.value.path;return"/"===e?"/"===o:o===e},d=()=>{window.scrollTo({top:0,behavior:"smooth"})},m=async(e,o)=>{("/"!==o||g.value)&&(e.preventDefault(),window.location.href="/"),M(),d()},h=!1,f=()=>{h||(window.requestAnimationFrame(()=>{u.value=window.scrollY>0,h=!1}),h=!0)},b=o=>{e.value=o,localStorage.setItem("preferred-language",o),g.value&&window.location.reload(),d()},E=()=>{i.value=!i.value,document.body.style.overflow=i.value?"hidden":""},M=()=>{i.value=!1,document.body.style.overflow=""};return a(()=>{window.addEventListener("scroll",f,{passive:!0}),s.afterEach(()=>{d()});try{let o=localStorage.getItem("preferred-language");o&&LANGUAGES.includes(o)&&(e.value=o)}catch(t){console.error("Error loading language preference:",t)}}),r(()=>{window.removeEventListener("scroll",f),document.body.style.overflow=""}),{languages:LANGUAGES,menuItems:MENU_ITEMS,isSticky:u,isMobileMenuOpen:i,locale:e,logoPath:v,isHomePage:g,isLoading:c,isActiveRoute:p,handleNavigation:m,changeLanguage:b,toggleMobileMenu:E,closeMenu:M}}});
</script>
<style src="@/assets/css/component/HeaderCom.css"></style>
