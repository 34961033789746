<template>
<div class="wrapper">
<NavbarDs />
<aside class="main-sidebar sidebar-dark-primary elevation-4">
<a href="index3.html" class="brand-link">
<img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity:.8">
<span class="brand-text font-weight-light">Pura MICS</span>
</a>
<SidebarDs />
</aside>
<div class="content-wrapper">
<section class="content">
<div class="container-fluid">
<div class="card card-primary card-outline">
<div class="card-header">
<div class="col-6">
<h5>Blog Baru</h5>
</div>
<div class="text-right">
<router-link to="/TampilanDetailProduk" class="btn btn-primary" style="color:#fff">
<i class="fas fa-angle-left" style="color:#fff"> </i> Kembali
</router-link>
</div>
</div>
<form @submit.prevent="store" class="card-body">
<div class="form-group">
<label for="postTitle">Nama Detail Produk EN:</label>
<input type="text" id="nama_detail_produk_en" v-model="post.nama_detail_produk_en" class="form-control" placeholder="Masukkan Detail Produk Inggris">
</div>
<div class="form-group">
<label for="postTitle">Nama Detail Produk ID:</label>
<input type="text" id="nama_detail_produk_id" v-model="post.nama_detail_produk_id" class="form-control" placeholder="Masukkan Detail Produk Indo">
</div>
<div class="form-group">
<label for="category">Nama Produk :</label>
<select v-model="post.id_produk" class="form-control">
<option disabled selected>Pilih Porduk</option>
<option v-for="produk in produk" :key="produk.id_produk" :value="produk.id_produk">
{{ produk.judul_en }}
</option>
</select>
</div>
<div class="form-group">
<label for="imageUpload">Image:</label>
<input type="file" id="gambar" class="form-control-file" @change="handleImageUpload">
<img v-if="imagePreview" :src="imagePreview" alt="Preview" style="max-width:200px;max-height:200px;margin-top:10px">
</div>
<div class="form-group">
<button type="submit" class="btn btn-primary">Submit</button>
</div>
</form>
</div>
</div>
</section>
</div>
<FooterDs />
<aside class="control-sidebar control-sidebar-dark">
</aside>
</div>
</template>
<script>
import{defineAsyncComponent as a}from"vue";import t from"axios";let NavbarDs=a(()=>import("@/components/NavbarDs.vue")),FooterDs=a(()=>import("@/components/FooterDs.vue")),SidebarDs=a(()=>import("@/components/SidebarDs.vue"));export default{name:"CombinedComponent",components:{FooterDs,NavbarDs,SidebarDs},data:()=>({produk:[],post:{nama_detail_produk_en:"",nama_detail_produk_id:"",id_produk:null,gambar:null},validationErrors:[],imagePreview:"",isSubmitting:!1}),async mounted(){await this.fetchProduk()},methods:{async fetchProduk(){try{let a=await t.get("https://puracigarettesolutions.com:3000/api/produk/TampilanProduk");this.produk=a.data.data}catch(r){console.error("Error saat mengambil data Produk:",r)}},async store(){if(!this.validateForm()){alert("Please fill in all required fields.");return}this.isSubmitting=!0;let a=new FormData;a.append("nama_detail_produk_en",this.post.nama_detail_produk_en),a.append("nama_detail_produk_id",this.post.nama_detail_produk_id),a.append("id_produk",this.post.id_produk),a.append("gambar",this.post.gambar);try{let r=await t.post("https://puracigarettesolutions.com:3000/api/detailproduk/CreateDetailProduk",a);console.log("Submitted:",r.data),this.$router.push({name:"TampilanDetailProduk"})}catch(e){this.handleErrors(e)}finally{this.isSubmitting=!1}},handleErrors(a){a.response&&a.response.data?this.validationErrors=a.response.data:(console.error("Unexpected error structure:",a),this.validationErrors=[{param:"error",msg:"Unexpected error occurred"}])},validateForm(){return this.post.gambar},handleImageUpload(a){this.post.gambar=a.target.files[0],this.imagePreview=URL.createObjectURL(a.target.files[0])}}};
</script>